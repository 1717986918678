<template>
  <div class="container pd100">
    <div class="card mb10">
      <van-image class="card-media" :src="detail.course_cover" />
      <div class="card-inner">
        <div class="card-title ui-ellipsis">{{ detail.course_name }}</div>
        <div class="card-txt">{{ detail.class_date }} {{ detail.class_time }}</div>
      </div>
    </div>
    <van-cell title="上课学员" />
    <div class="studen-list">
      <div v-for="item in course_students" :key="item.student_id" class="studen-list-item" @click="onNavigator({path:'/teacher/achievement-toggle?book_id='+item.book_id})">
        <div class="studen-list-item-media">
          <van-image :src="item.student_avatar" class="studen-list-item-media-img" />
        </div>
        <div class="studen-list-item-inner">
          <div class="studen-list-item-title">
            {{ item.student_name }}
          </div>
        </div>
        <div class="studen-list-item-ft">
          <!-- <span>去发放</span> -->
          <van-icon name="arrow" size="16" color="#999" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'TeacherScheduleAchievement',
  mixins: [PageMixin],
  data() {
    return {
      detail: {},
      course_studio: {},
      course_students: []
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$api.employee_scheduledetail({ course_class_id: this.$route.query.course_class_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_students = res.data.course_students
      })
    }

  }
}
</script>
<style lang="less" scoped>
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #fff;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }

  .studen-list{

  }

  .studen-list-item{
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 10px;
      &-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-icon{
      background: #5499f6;
      color: #fff;
      display: inline-block;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-right: 5px;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-inner{
      padding-right: 30px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
    &-ft{
      font-size: 14px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

</style>

